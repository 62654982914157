<template>
    <div class="bg-2 page-sidebar" id="top">

        <!--        <lego-nav-bar drop-down-animation="bounce" :items="menu"/>-->

        <Header @navOpen="sideNav"/>
        <!--        <sidebar/>-->

        <nav id="sidebar">
            <lego-side-bar drop-down-animation="fade" :items="menu" #logo >
                <div class="sidebar-header">
                    <div class="row">
                        <div class="pl-3">
                            <div class="user-name-l"><b>A R</b></div>
                        </div>
                        <div class="col d-flex fl-a-c name">
                            <div class="row">
                                <div class="col-12"><b>Ajil Raj <a href=""></a></b></div>
                                <div class="col-12">ajilraj@xeoscript.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </lego-side-bar>
            <!--            <div id="dismiss" class="d-none">-->
            <!--                <i class="fa fa-arrow-left"></i>-->
            <!--            </div>-->

            <!--            <div class="sidebar-header">-->
            <!--                <div class="row">-->
            <!--                    <div class="pl-3">-->
            <!--                        <div class="user-name-l"><b>A R</b></div>-->
            <!--                    </div>-->
            <!--                    <div class="col d-flex align-items-center ">-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-12"><b>Ajil Raj <a href=""></a></b></div>-->
            <!--                            <div class="col-12">ajilraj@xeoscript.com</div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <ul class="list-unstyled components">-->
            <!--                <li class="sidebar-items active">-->
            <!--                    <drop-down-button block text="Home" enter-animation="zoomIn" exit-animation="zoomOut" icon-alignment="right" icon="fa fa-chevron-right">-->
            <!--                        <drop-down-item @click="clicked">-->
            <!--                            <router-link to="/cart-login">Login</router-link>-->
            <!--                        </drop-down-item>-->
            <!--                        <drop-down-item @click="clicked">-->
            <!--                            <router-link to="/">Home 2</router-link>-->
            <!--                        </drop-down-item>-->
            <!--                        <drop-down-item @click="clicked">-->
            <!--                            <router-link to="/">Home 3</router-link>-->
            <!--                        </drop-down-item>-->
            <!--                    </drop-down-button>-->
            <!--                </li>-->
            <!--            </ul>-->

        </nav>
        <Subnav/>

        <div class="body-content">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <div class="overlay" id="overlay" @click="clicked"></div>
        <div class="bg-dark p-3">
            Powered by Xeoscript Technologies
        </div>
    </div>

</template>

<script>
import LegoSideBar from '@lego-components/SideNavBar';
import getMenu from '../data/menu';
import Header from '../views/components/header';
// import Sidebar from '../views/components/sidebar';
import Subnav from '../views/components/subnav';

export default {
    components: {
        Subnav,
        // Sidebar,
        Header,
        LegoSideBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Lego Components',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    methods: {
        sideNav () {
            const element1 = document.getElementById('sidebar');
            const element2 = document.getElementById('overlay');

            if (this.customClass === 'active') {
                // this.customClass = '';
                // document.body.classList.remove('open-menu');
                element1.classList.remove('active');
                element2.classList.remove('active');
            } else {
                // this.customClass = 'active';
                // document.body.classList.add('open-menu');
                element1.classList.add('active');
                element2.classList.add('active');
            }
        },
        clicked () {
            // alert('hauii');
            const element1 = document.getElementById('sidebar');

            const element2 = document.getElementById('overlay');

            this.customClass = '';
            // document.body.classList.remove('open-menu');
            element1.classList.remove('active');
            element2.classList.remove('active');
        }
    },
    name: 'MainLayout'
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.sidebar-items {
    .dropdown {
        width: 100%;

        button {
            text-align: left;
            background: white;
            color: black;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: none;

            &:hover {
                background: white;
                color: black;
            }
        }

        .dropdown-menu {
            width: 100%;
            font-size: 1rem;
            box-shadow: none;
            position: unset;
        }
    }
}
</style>
