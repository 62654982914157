<template>
    <nav>
        <header class="header container-fluid">
            <div class="fl-eq-c">
                <button type="button" class="hamburger lego-btn" id="sidebarCollapse" @click="sideNav">
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                <router-link to="/" class="logo" href="#">
                    <img alt="logo" src="../../assets/image/logo.png">
                </router-link>
            </div>
            <form class="search d-none d-md-block">
                <input type="text" name="search">
                <button type="submit"><i class="fa fa-search"></i></button>
            </form>
            <ul class="main-nav d-none d-md-flex">
                <li>
                    <router-link to="/myaccount/" href="#">
                        <span class="icon-user"></span>Ajil Raj
                    </router-link>
                </li>
                <li><a href="#"><span class="icon-bag"></span>Orders</a></li>
                <li><a href="#"><span class="icon-user"></span>Cart</a></li>
            </ul>
            <ul class="main-nav-mob d-flex d-md-none">
                <li><router-link to="#"><span class="icon-bag"></span></router-link></li>
                <li><router-link to="#"><span class="icon-shopping-cart"></span></router-link></li>
                <li><router-link to="/myaccount/"><span class="icon-user"></span></router-link></li>
            </ul>

        </header>
    </nav>
</template>

<script>
export default {
    name: 'header',
    methods: {
        sideNav () {
            this.$emit('navOpen');
        }
    }
};
</script>

<style scoped>

</style>
