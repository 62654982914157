import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: 'ni ni-zoom-split-in',
            text: 'Home',
            link: '#',
            items: [
                {
                    text: 'Login',
                    link: '/cart-login'
                },
                {
                    text: 'Home 2',
                    link: '#'
                },
                {
                    text: 'Home 3',
                    link: '#'
                }
            ]
        },
        {
            text: 'Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data 1',
                    link: '#'
                },
                {
                    text: 'Master Data 3',
                    link: '#'
                }
            ]
        }
    ];

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
