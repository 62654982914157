<template>
    <div>
        <section class="d-xl-none">
            <div class="container-fluid p-0">
                <div class="mob-sub-nav-menu">
                    <div class="row text-center m-0">
                        <div class="col-6 mob-side-nav-content mt-1 border-right-b p-2 position-relative nav-delivery-main">
                            <a class="fl-x-c"><span class="icon-Location_delivery"></span>Delivery Location</a>
                            <div class="mob-sub-nav-drop nav-delivery">
                                <ul class="list-unstyled m-0">
                                    <li><a href="">part 1</a></li>
                                    <li><a href="">part 2</a></li>
                                    <li><a href="">part 3</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-6 mob-side-nav-content mt-1 p-2 nav-category-main">
                            <a class="fl-x-c"><span class="icon-smartphone-call"></span>Categories</a>
                            <div class="mob-sub-nav-drop nav-category">
                                <ul class="list-unstyled m-0">
                                    <li><a href="">part 1</a></li>
                                    <li><a href="">part 2</a></li>
                                    <li><a href="">part 3</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section class="d-none d-xl-block box-shadow-1">
            <div class="sub-nav-menu">
                <ul class=" flex-row d-flex list-inline mb-0 ">
                    <li class="delivery-border-right col col-xl-2 p-0">
                        <a href=""><span class="icon-Location_delivery"></span>Delivery Location</a>
                    </li>
                    <li class="col p-0">
                        <a href=""><span class="icon-smartphone-call"></span>Mobile</a>
                    </li>
                    <li class="col p-0">
                        <a href=""><span class="icon-laptop"></span>Electronics</a></li>
                    <li class="col p-0">
                        <a href=""><span class="icon-high-heel"></span>Fashion</a></li>
                    <li class="col p-0">
                        <a href=""><span class="icon-rocking-horse"></span>Kids Toys</a>
                    </li>
                    <li class="col p-0">
                        <a href=""><span class="icon-briefcase"></span>Bags</a></li>
                    <li class="col p-0">
                        <a href="">More</a>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'subnav'
};
</script>

<style scoped lang="scss">
.mob-side-nav-content {
    &:active {
        color: #eb0061;
    }

    &:focus {
        color: #eb0061;
    }

    &:visited {
        color: #eb0061;
    }
}
</style>
